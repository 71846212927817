.bubble {
    position: absolute;
    width: 70px;
    height: 70px;
    border: 1px solid white;
    box-shadow: 0px 0px 20px 5px rgba(90, 90, 255, 0.275);
    background-color: rgba(255, 255, 255, 0.897);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    transition: 0.2s ease-out;
    z-index: -10;
}
@media screen and (max-width : 500px) {
    .bubble {
        display: none;
    }
}