.container-projet {
  width: 80%;
  text-align: center;
  margin: 0 auto;

  h1 {
    margin: 30px 0;
    font-size: 4rem;
    color: grey;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.249);
  }
  .img {
    display: block;
    position: relative;
    width: 80vw;
    margin: 0 auto;

    img {
      width: 100%;
      border-radius: 20px;
    }
    .hover {
      width: 100%;
      height: 100%;
      background-color: black;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: 0.5s;
      border-radius: 20px;
    }
    &:hover {
      .hover {
        opacity: 0.9;
      }
      button {
        opacity: 1;
      }
    }
    button {
      position: absolute;
      z-index: 40;
      opacity: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 30px;
      padding: 30px 60px;
      border-radius: 10px;
      border: none;
      background-color: rgb(228, 9, 9);
      transition: 0.3s;
      font-weight: 900;
      box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.841);

      a {
        color: white;
      }
      &:hover {
        cursor: pointer;
        scale: 1.03;
      }
    }
  }

  a {
    text-decoration: underline;
  }
}
