.navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 0px 5px 2px rgba(62, 120, 236, 0.572);
    font-weight: 700;
    z-index: 30;

    li {
        color: rgb(40, 40, 40);
        transition: 0.3s;
        padding: 20px ;
        &:hover {
        color: rgb(0, 0, 0);
        cursor: pointer;
        }
    }

    .portfolio {

        .projects {
            display: none;
            position: absolute;
            width: 100vw;
             min-height: 100vh;
            background-color: rgba(151, 187, 255, 0.6);
            left: 50%;
            top: 60px;
            backdrop-filter: blur(20px);
            z-index: 50;
        }
        &:hover {
            .projects {
                display: flex;
                justify-content: space-evenly;
                flex-direction: column;
                animation: anim 0.8s  forwards  ease-out;

                :nth-child(1) {
                    animation: animli 2.2s 0s forwards  ease-in-out;
                }
                :nth-child(2) {
                    animation: animli 2.6s 0s forwards  ease-in-out;
                }
                :nth-child(3) {
                    animation: animli 3s 0s forwards  ease-in-out;
                }

                li {
                    padding-top: 20px;
                    width: 100%;
                    text-align: center;
                    letter-spacing: 0.5rem;
                    line-height: 30px;
                    opacity: 0;
                    //  transform: translateX(-50%);
                    // animation: animli 2s 0s forwards  ease-in-out;

                  
                }
            }
        }
    }
    
   
}

@keyframes anim {
    from {
        opacity: 0;
        transform: translate(-50% , -100%);
    }
    to {
        opacity: 1;
        transform: translate(-50% , 0%);
    }
}
@keyframes animli  {
    from {
        opacity: 0;
        transform: translateX(-50%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}