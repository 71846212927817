.formulaire-container {
  background-color: rgba(171, 214, 249, 0.204);
  height: 93vh;
h1 {
 
    background: 
     linear-gradient(156deg, rgb(173, 215, 255) 0%, rgb(100, 180, 255) 4%, rgb(43, 97, 245) 61%, rgb(174, 0, 255) 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 3rem;
    padding-top: 30px;
    }
    
    
    .formulaire {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin: 0px auto;
      padding: 20px;
      border-radius: 20px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.207);
      position: relative;
      max-width: 400px;
      min-width: 300px;
      background-color: white;
    }
    
    .input-div {
      width: 100%;
      margin: 10px 0;
      
    }
    
    .formulaire input {
      width: 100%;
      line-height: 25px;
      font-size: 15px;
      border: none;
      border-bottom: 2px solid rgba(128, 128, 128, 0.58);
    }
    .formulaire input:focus {
     transition: 0.3s;
      outline: none;
      border-bottom: 2px solid rgba(58, 91, 255, 0.771);

      box-shadow: 0px 12px 15px -10px  rgba(58, 91, 255, 0.223);;
      
      
    }
    .formulaire textarea {
      resize: none;
      overflow: scroll;
      border: none;
      line-height: 23px;
      width: 100%;
      font-size: 15px;
      position: relative;
      border-bottom: 2px solid rgba(128, 128, 128, 0.58);
    }
    
    .formulaire textarea:focus {
     
      outline: none;
      transition: 0.3s;
      border-bottom: 2px solid rgba(58, 91, 255, 0.771);
      box-shadow: 0px 12px 15px -10px  rgba(58, 91, 255, 0.223);;
      
    }
    
    #btn-form {
    
      color: white;
      font-weight: 900;
      width: 150px;
      margin: 30px  auto;
      padding: 7px;
      border-radius: 8px;
      border: none;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.417);
      background: 
      linear-gradient(156deg, rgb(43, 97, 245) 61%, rgb(174, 0, 255) 100%, rgb(173, 215, 255) 0%, rgb(100, 180, 255) 4%);
      transition: 0.3s;
    }
    
    
    #btn-form:hover {
      background: 
      linear-gradient(156deg, rgb(174, 0, 255) 0%, rgb(174, 0, 255) 4%, rgb(43, 97, 245) 61%, rgb(174, 0, 255) 100%);
      cursor: pointer;
      scale: 1.01;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.206);
    }
}