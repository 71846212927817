.home {

    background-color: rgba(171, 214, 249, 0.219);
    width: 100vw;
    height: 65vh;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;


.home-container {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
    // align-items: center;
    width: 80%;
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    @media screen and ( max-width : 550px) {

        img {
            position: relative;
            width: 200px;
        }
        display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        
    }
    @media screen and ( max-width : 800px) {
            max-width: 500px;
            
    }
    
    .left-container {
        position: absolute;
        top: 0;
        left: 0;
        @media screen and ( max-width : 550px) {
            img {

                width: 200px;
            }
               position: relative;
            
        }
       
      
  

    h1 {
        background: 
        linear-gradient(156deg, rgb(173, 215, 255) 0%, rgb(100, 180, 255) 4%, rgb(43, 97, 245) 61%, rgb(174, 0, 255) 100%);
       background-clip: border-box;
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       text-align: center;
       font-size: 4rem;
       font-weight: 900;
       margin-top: 30px;
       @media screen and ( max-width : 800px) {
        
       
            font-size: 3rem;
      
       }
    }
    h2 {
        padding-bottom: 30px;
    }
}
.right-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
        position: absolute;
        bottom: 0;
        right: 0;

        @media screen and ( max-width : 550px) {
          position: relative;
    }
}
}
   

        @media screen and ( max-width : 800px) {
            img {
                width: 300px;
            }
            h1 {
                font-size: 3rem;
            }
            h2 {
                font-size: 1.5rem;
            }
        }
        @media screen and ( max-width : 550px) {
            img {

                width: 200px;
            }
            h1 {
                position: relative;
            }
               
            
        }
    
}
}